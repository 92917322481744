import {NgModule} from '@angular/core';
import {CmsComponent} from './cms.component';
import {Routes, RouterModule} from '@angular/router';
import {EmployeeProfileGuard, ProjectGuard} from '../_guards';
import {EmployeeGuard} from '../_guards';
import {AgencyGuard} from '../_guards';
import {ClientGuard} from '../_guards';
import {AgencyClientGuard} from '../_guards';
import {WorkValidationGuard} from '../_guards';
import {ClientWorkValidationGuard} from '../_guards';
import {AuthGuard} from '../auth/controllers/auth.guard';
import { WorkValidationDetailsGuard } from 'app/_guards/work-validation-details.guard';
import { PayrollGuard } from 'app/_guards/payroll.guard';
import { SupervisorOnSiteGuard } from 'app/_guards/supervisor-on-site.guard';
// import {AngularFireModule} from '@angular/fire';

const config = {
  apiKey: 'AIzaSyBM-5MzyZoh0hCJJtU0JrJCvcSbQR1f_44',
  authDomain: 'jobrocks-223208.firebaseapp.com',
  databaseURL: 'https://jobrocks-223208.firebaseio.com',
  projectId: 'jobrocks-223208',
  storageBucket: 'jobrocks-223208.appspot.com',
  messagingSenderId: '707476098256'
};
const routes: Routes = [
  {
    'path': 'project',
    'component': CmsComponent,
    'canActivate': [ProjectGuard],
    'children': [
      {
        'path': '',
        'loadChildren': '.\/pages\/default\/project\/dashboard\/dashboard.module#ProjectDashboardModule'
      },
      {
        'path': 'basic-info',
        'loadChildren': '.\/pages\/default\/project\/basic-information\/basic-information.module#BasicInformationModule'
      },
      {
        'path': 'basic-info/:id',
        'loadChildren': '.\/pages\/default\/project\/basic-information\/basic-information.module#BasicInformationModule'
      },
      {
        'path': 'project-planning/:id',
        'loadChildren': '.\/pages\/default\/project\/project-planning\/project-planning.module#ProjectPlanningModule'
      },
      {
        'path': 'ppc\/project-planning/:id',
        'loadChildren': '.\/pages\/default\/project\/pay-per-call\/project-planning\/project-planning.module#PPCProjectPlanningModule'
      },

      {
        'path': 'project-publishing/:id',
        'loadChildren': '.\/pages\/default\/project\/project-publishing\/project-publishing.module#ProjectPublishingModule'
      },
      {
        'path': 'staff-allocation/:id',
        'loadChildren': '.\/pages\/default\/project\/staff-allocation\/staff-allocation.module#StaffAllocationModule'
      },
      {
        'path': 'ppc\/staff-allocation/:id',
        'loadChildren': '.\/pages\/default\/project\/pay-per-call\/staff-allocation\/staff-allocation.module#PPCStaffAllocationModule'
      },
      // {
      //   'path': 'work-validation\/dashboard',
      //   'loadChildren':
      //     '.\/pages\/default\/project\/work-validation\/dashboard\/dashboard.module#DashboardModule'
      // },
      // {
      //   'path': 'work-validation\/details\/:id',
      //   'loadChildren':
      //     '.\/pages\/default\/project\/work-validation\/details\/details.module#DetailsModule'
      // },
      {
        'path': 'expenses-validation\/dashboard',
        'loadChildren':
          '.\/pages\/default\/project\/expenses-validation\/dashboard\/dashboard.module#DashboardModule'
      },
      {
        'path': 'expenses-validation\/details\/:id',
        'loadChildren':
          '.\/pages\/default\/project\/expenses-validation\/details\/details.module#DetailsModule'
      },
      {
        'path': 'project-staff/:id',
        'loadChildren': '.\/pages\/default\/project\/project-staff\/project-staff.module#ProjectStaffModule'
      },
      {
        'path': 'chat/:id',
        'loadChildren': '.\/pages\/default\/project\/project-chat\/chat.module#ChatModule'
      },
    ]
  },
  {
    'path': 'work-validation',
    'component': CmsComponent,
    'canActivate': [WorkValidationGuard],
    'children': [
      {
        'path': 'dashboard',
        'loadChildren':
          '.\/pages\/default\/project\/work-validation\/dashboard\/dashboard.module#DashboardModule'
      },
      {
        'path': 'details\/:id',
        'loadChildren':
          '.\/pages\/default\/project\/work-validation\/details\/details.module#DetailsModule',
        'canActivate': [WorkValidationDetailsGuard],
      },
    ]
  },
  {
    'path': 'client-work-validation',
    'component': CmsComponent,
    'canActivate': [ClientWorkValidationGuard],
    'children': [
      {
        'path': 'dashboard',
        'loadChildren':
          '.\/pages\/default\/project\/client-work-validation\/dashboard\/dashboard.module#DashboardModule'
      },
      {
        'path': 'details\/:id',
        'loadChildren':
          '.\/pages\/default\/project\/client-work-validation\/details\/details.module#DetailsModule',
        'canActivate': [WorkValidationDetailsGuard],
      },
    ]
  },
  {
    'path': 'profile',
    'component': CmsComponent,
    'canActivate': [EmployeeProfileGuard],
    'children': [
      {
        'path': '',
        'loadChildren': '.\/pages\/default\/employee\/profile\/profile.module#EmployeeProfileModule'
      }
    ]
  },
  {
    'path': 'employee',
    'component': CmsComponent,
    'canActivate': [EmployeeGuard],
    'children': [
      {
        'path': '',
        'loadChildren': '.\/pages\/default\/employee\/dashboard\/dashboard.module#EmployeeDashboardModule'
      },
      {
        'path': 'profile/:id',
        'loadChildren': '.\/pages\/default\/employee\/profile\/profile.module#EmployeeProfileModule'
      },
      {
        'path': 'pinwall',
        'loadChildren': '.\/pages\/default\/employee\/dashboard\/dashboard.module#EmployeeDashboardModule'
      },
      {
        'path': 'filter',
        'loadChildren': '.\/pages\/default\/employee\/dashboard\/dashboard.module#EmployeeDashboardModule'
      },
      {
          'path': 'superadmin',
          'loadChildren': '.\/pages\/default\/superadmin\/superadmin-dashboard\/superadmin-dashboard.module#SuperadminDashboardModule'
      },
      {
          'path': 'superadmin/profile/:id',
          'loadChildren': '.\/pages\/default\/superadmin\/profile\/profile.module#EmployeeProfileModule'
      },
    ]
  },
  {
    'path': 'supervisor-on-site',
    'component': CmsComponent,
    'canActivate': [SupervisorOnSiteGuard],
    'children': [
      {
        'path': '',
        'loadChildren': '.\/pages\/default\/supervisor-on-site\/dashboard\/dashboard.module#TimestampSupervisorDashboardModule'
      },
      {
        'path': 'details/:id',
        'loadChildren': '.\/pages\/default\/supervisor-on-site\/details\/details.module#TimestampSupervisorDetailsdModule'
      },
    ]
  },
  {
    'path': 'agency',
    'component': CmsComponent,
    'children': [
      {
        'path': 'profile',
        'loadChildren': '.\/pages\/default\/agency\/profile\/profile.module#AgencyProfileModule',
        'canActivate': [AgencyGuard],
      },
      {
        'path': 'profile/:id',
        'loadChildren': '.\/pages\/default\/agency\/profile\/profile.module#AgencyProfileModule',
        'canActivate': [AgencyGuard],
      },
      {
        'path': 'dashboard',
        'loadChildren': '.\/pages\/default\/agency\/dashboard\/dashboard.module#AgencyDashboardModule',
        'canActivate': [AgencyGuard],
      },
      {
        'path': 'admin\/profile',
        'loadChildren': '.\/pages\/default\/agency\/admin-profile\/profile.module#AgencyAdminProfileModule',
        'canActivate': [AgencyGuard],
      },
      {
        'path': 'credits/:id',
        'loadChildren': '.\/pages\/default\/agency\/credits\/credits.module#CreditsModule',
        'canActivate': [AgencyGuard],
      },
      {
        'path': 'payroll',
        'loadChildren': '.\/pages\/default\/agency\/payroll\/payroll.module#PayrollModule',
          canActivate: [PayrollGuard] 
      },
      {
        'path': 'edit-templates',
        'loadChildren': '.\/pages\/default\/agency\/edit-templates\/edit-templates.module#EditTemplatesModule',
        'canActivate': [AgencyGuard],
      },
      {
        'path': 'sedcard',
        'loadChildren': '.\/pages\/default\/agency\/sedcard\/sedcard.module#SedcardModule',
        'canActivate': [AgencyGuard],
      },
      {
        path: 'sedcard/:id',
        loadChildren: './pages/default/agency/sedcard/sedcard.module#SedcardModule',
        'canActivate': [AgencyGuard],
      },
      {
        'path': 'work-report/:id',
        'loadChildren': '.\/pages\/default\/agency\/work-report\/work-report.module#WorkReportModule',
        'canActivate': [AgencyGuard],
      },
      {
        'path': 'employee-settings',
        'loadChildren': '.\/pages\/default\/agency\/employee-settings\/employee-settings.module#EmployeeSettingsModule',
        'canActivate': [AgencyGuard],
      },
    ]
  },
  {
    'path': 'agency-client',
    'component': CmsComponent,
    'canActivate': [AgencyClientGuard],
    'children': [
      {
        'path': 'profile/:id',
        'loadChildren': '.\/pages\/default\/client\/settings\/settings.module#SettingsModule'
      },
    ]
  },
  {
    'path': 'client',
    'component': CmsComponent,
    'canActivate': [ClientGuard],
    'children': [
      {
        'path': 'dashboard',
        'loadChildren': '.\/pages\/default\/client\/dashboard\/client-dashboard.module#ClientDashboardModule'
      },
      {
        'path': 'profile',
        'loadChildren': '.\/pages\/default\/client\/profile\/profile.module#ClientProfileModule'
      },
      {
        'path': 'profile/:id',
        'loadChildren': '.\/pages\/default\/client\/profile\/profile.module#ClientProfileModule'
      },
    ]
  },

  // to be settled:

  {
    'path': '',
    'component': CmsComponent,
    'canActivate': [AuthGuard],
    'children': [
      {
        'path': 'user',
        'loadChildren': '.\/pages\/default\/user\/profile\/profile.module#ProfileModule'
      },
      {
        'path': 'settings\/dashboard',
        'loadChildren': '.\/pages\/default\/settings\/dashboard\/dashboard.module#SettingsDashboardModule'
      },
      {
        'path': 'locations\/dashboard',
        'loadChildren': '.\/pages\/default\/locations\/dashboard\/dashboard.module#LocationsDashboardModule'
      },
      {
        'path': 'agency\/translates/:id',
        'loadChildren': '.\/pages\/default\/agency\/translates\/translate.module#AgencyTranslateModule'
      },
      {
        'path': 'translate',
        'loadChildren': '.\/pages\/default\/translate\/translate-table.module#TranslateTableModule'
      },
      {
        'path': 'not-found',
        'loadChildren': '.\/pages\/self-layout\/self-layout.module#SelfLayoutModule'
      },
      {
        'path': 'client-settings',
        'loadChildren': '.\/pages\/default\/client\/settings\/settings.module#SettingsModule'
      },
      {
        'path': 'orders',
        'loadChildren': '.\/pages\/default\/client\/orders\/orders.module#OrdersModule'
      },
      {
        'path': 'staff',
        'loadChildren': '.\/pages\/default\/client\/staff\/staff.module#StaffModule'
      },
    ]
  },
  {
    'path': '**',
    'redirectTo': '',
    'pathMatch': 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    // AngularFireModule.initializeApp(config),
  ],
  exports: [RouterModule]
})
export class CmsRoutingModule {
}
