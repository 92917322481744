import {
  Component,
  Input,
  OnChanges,
  Output,
  EventEmitter,
  ChangeDetectorRef,
} from "@angular/core";
import { AppConfig } from "../../common/app.config";
import { Router } from "@angular/router";
import { LazyLoadEvent } from "primeng/api";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-custom-table",
  templateUrl: "./custom-table.component.html",
  styleUrls: ["./custom-table.component.scss"],
})
export class CustomTableComponent implements OnChanges {
  public showErrMsg = false;
  public validatorModal = false;
  public expensesModal = false;
  public wagesModal = false;
  public today = Date.now();
  public loaderStripesArray = new Array(1);
  public currentLanguage = localStorage.getItem("Language");

  public text =
    this.currentLanguage == "de"
      ? "Einträge {first} bis {last} von {totalRecords} Einträgen"
      : this.currentLanguage == "fr"
      ? "entrées {first} à {last} de {totalRecords} entrées"
      : this.currentLanguage == "it"
      ? "voci {first} a {last} da {totalRecords} voci"
      : "Showing {first} to {last} of {totalRecords} entries";

  @Input() tableName: any;
  @Input() orders_budget: any;
  @Input() data: any;
  @Input() columns: any;
  @Input() rowsNumber: any;
  @Input() paginator: any;
  @Input() showCurrentPageReport: any;
  @Input() showValidatorConfig: any;
  @Input() requireValidaton: any;
  @Input() numberOfValidators: any;
  @Input() calendarConfig: any;
  @Input() isNotAddable: any;
  @Input() isNotEditable: any;
  @Input() isNotDeletable: any;
  @Input() hasNoActions: any;
  @Input() isAdminAndPMDropdownDisabled: any;
  @Input() isDeactivatable: any;
  @Input() selectedEmployeeIds: any;
  @Input() rowsSelected: any;
  @Input() link: any;
  @Input() customLinkData: string;
  @Input() isBlankTarget: any;
  @Input() noDataText: any;
  @Input() deletionTitle: any;
  @Input() dashboardQueryParams: any;
  @Input() projectTab: any;
  @Input() xmlPath: any;
  @Input() isEmployee: any;
  @Input() editingWithIndex: any;
  @Input() isLazyLoaded: any;
  @Input() totalRecords: any;
  @Input() loading: boolean = false;
  @Input() canEditWorkValidation: boolean = true;

  @Output() add = new EventEmitter<any>();
  @Output() budget = new EventEmitter<any>();
  @Output() edit = new EventEmitter<any>();
  @Output() delete = new EventEmitter<any>();
  @Output() reInviteUser = new EventEmitter<any>();
  @Output() openXml = new EventEmitter<any>();
  @Output() blur = new EventEmitter<any>();
  @Output() uploadCalendar = new EventEmitter<any>();
  @Output() editEmployeeHours = new EventEmitter<any>();
  @Output() syncMinBreak = new EventEmitter<any>();
  @Output() updateAdminAndPMRole = new EventEmitter<any>();
  @Output() resendInvitation = new EventEmitter<any>();
  @Output() viewMap = new EventEmitter<any>();
  @Output() deactivate = new EventEmitter<any>();
  @Output() selectDeselectAll = new EventEmitter<any>();
  @Output() checkListChanged = new EventEmitter<any>();
  @Output() checkListChangedNoArg = new EventEmitter<any>();
  @Output() rateEmployee = new EventEmitter<any>();
  @Output() validateTimestamp = new EventEmitter<any>();
  @Output() projectCheckboxChanged = new EventEmitter<any>();
  @Output() exportProjectCostOverview = new EventEmitter<any>();
  @Output() archiveProject = new EventEmitter<any>();
  @Output() discardASP = new EventEmitter<any>();
  @Output() editUnavailability = new EventEmitter<any>();
  @Output() deleteUnavailability = new EventEmitter<any>();
  @Output() acceptUnavailability = new EventEmitter<any>();
  @Output() rejectUnavailability = new EventEmitter<any>();
  @Output() editWithIndex = new EventEmitter<any>();
  @Output() deleteWithIndex = new EventEmitter<any>();
  @Output() lazyLoad = new EventEmitter<any>();
  @Output() clickMessage = new EventEmitter<any>();
  @Output() addClock = new EventEmitter<any>();

  isEditing = false;
  editableBudget: number;
  constructor(
    private router: Router,
    private cdr: ChangeDetectorRef,
    private translateService: TranslateService
  ) {}

  ngOnChanges(): void {}

  isPaginator(data) {
    return data?.length > 0 ? true : false;
  }

  addNewItem() {
    this.add.emit();
  }

  editItem(data) {
    this.edit.emit(data);
  }

  deleteItem(id) {
    this.delete.emit(id);
  }

  reinviteDeletedUser(id){
    this.reInviteUser.emit(id);
  }

  openXML(data) {
    this.openXml.emit(data);
  }

  blurItem(index) {
    this.blur.emit(index);
  }

  onCalendarUpload($event) {
    this.uploadCalendar.emit($event);
  }

  onEditEmployeeHours(id) {
    this.editEmployeeHours.emit(id);
  }

  onSyncMinBreak(index) {
    this.syncMinBreak.emit(index);
  }

  onUpdateAdminAndPMRole(data) {
    this.updateAdminAndPMRole.emit(data);
  }

  onResendInvitation(email) {
    this.resendInvitation.emit(email);
  }

  onViewMap(data) {
    this.viewMap.emit(data);
  }

  onDeactivateEmployee(employeeId, index) {
    this.deactivate.emit({ employeeId, index });
  }

  getThumbnailPath(userId) {
    return AppConfig.getUserPictureThumbnailPath(userId);
  }

  onSelectDeselectAll() {
    this.selectDeselectAll.emit();
  }

  onCheckListChanged(selected, employeeId) {
    this.checkListChanged.emit({ selected, employeeId });
  }

  onCheckListChangedNoArg() {
    this.checkListChangedNoArg.emit();
  }

  onRateEmployee(data) {
    this.rateEmployee.emit(data);
  }

  onValidateTimestamp(work, valid) {
    this.validateTimestamp.emit({ work, valid });
  }

  roundTotalWage(total) {
    let totalWage = total.split(" ");
    let roundedWage = Number.parseFloat(totalWage[0]).toFixed(2);
    return roundedWage + " " + totalWage[1];
  }

  getShortDescription(desc) {
    if (!desc) return "";
    let shortDesc = desc.slice(0, 38);
    let lastChar = shortDesc.charAt(shortDesc.length - 1);
    if (lastChar == "," || lastChar == " " || lastChar == ".") {
      shortDesc = shortDesc.substring(0, shortDesc.length - 1);
    }
    return shortDesc.length > 0 ? shortDesc + "..." : shortDesc;
  }

  onProjectCheckboxChanged(project, column) {
    console.log(column);
    this.projectCheckboxChanged.emit({ project, column });
  }

  navigateToChat(id) {
    this.router.navigate(["/project/chat/" + id]);
  }

  onExportProjectCostOverview(id) {
    this.exportProjectCostOverview.emit(id);
  }

  onArchiveProject(project, i) {
    this.archiveProject.emit({ project, i });
  }

  onDiscardASP(data) {
    this.discardASP.emit(data);
  }

  onEditUnavailability(data, index) {
    this.editUnavailability.emit({ data, index });
  }

  onDeleteUnavailability(index) {
    this.deleteUnavailability.emit(index);
  }

  onAcceptUnavailability(index) {
    this.acceptUnavailability.emit(index);
  }

  onRejectUnavailability(index) {
    this.rejectUnavailability.emit(index);
  }

  onEditWithIndex(index) {
    this.editWithIndex.emit(index);
  }

  onDeleteWithIndex(index) {
    this.deleteWithIndex.emit(index);
  }

  loadData(event: LazyLoadEvent) {
    this.lazyLoad.emit(event);
  }

  onClickMessage(data) {
    this.clickMessage.emit(data);
  }

  getLastClockType(employeeTimestamps: any[]): string | null {
    if (!employeeTimestamps || employeeTimestamps.length === 0) {
      return null;
    }
    return employeeTimestamps[employeeTimestamps.length - 1]?.clock_type || null;
  }

  onClock(type: string, data: any) {
    this.addClock.emit({'type': type , 'data': data});
  }

  isYesterday(date: string): boolean {
    const inputDate = new Date(date);
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
  
    return (
      inputDate.getDate() === yesterday.getDate() &&
      inputDate.getMonth() === yesterday.getMonth() &&
      inputDate.getFullYear() === yesterday.getFullYear()
    );
  }

  calculateMinutes(timestamps: any[], date: string): number | string {
    const lastClockOut = timestamps.filter((ts: any) => ts.clock_type === 'clock out').sort((a: any, b: any) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())[0];
  
    if (!lastClockOut) {
      return '';
    }
  
    if (!this.isYesterday(date)) {
      return '';
    }
  
    const createdAt = new Date(lastClockOut.created_at).getTime();
    const now = Date.now();
    const diffInMillis = now - createdAt;
    const elapsedMinutes = Math.floor(diffInMillis / (1000 * 60));
    const remainingTime = 15 - elapsedMinutes;

    return remainingTime > 0 ? remainingTime : 0;
  }

  toggleEdit(): void {
    this.isEditing = true;
    this.editableBudget = this.orders_budget;
  }

  saveBudget(): void {
    this.orders_budget = this.editableBudget;
    this.budget.emit(this.editableBudget);
    this.isEditing = false;
    this.cdr.detectChanges();
  }

  generateLink(dataItem: any): string | null {
    if (!this.link) {
      return null;
    }
    if(this.tableName == 'EmployeeJobHistoryTable' || this.tableName == 'FutureJobsTable') {
      return `${this.link}${dataItem.project_id}`;
    } else {
      return `${this.link}${dataItem.id}`;
    }
  }
}
