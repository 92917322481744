import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PermissionService } from 'app/_services';
import { MessageService } from 'primeng/api';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SupervisorOnSiteGuard implements CanActivate {

  constructor(
    private router: Router,
    private permissionService: PermissionService,
    private messageService: MessageService,
    private translate: TranslateService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (!currentUser) {
      return of(this.router.createUrlTree(['/login']));
    }

    return this.permissionService.canAccessRoute('supervisor-on-site').pipe(
      map((res) => {
        // If user has permission, allow access
        return res ? true : this.router.createUrlTree(['/login']);
      }),
      catchError((error) => {
        // Handle error and redirect based on permissions or show an error message
        return this.permissionService.sidebarPermissions().pipe(
          map((response) => this.resolveRedirect(response, currentUser)),
          catchError(() => {
            // If sidebarPermissions also fails, redirect to login
            this.translate.get('You do not have permissions to continue. Redirecting to login...')
              .subscribe((message) => {
                this.messageService.add({
                  severity: 'error',
                  summary: this.translate.instant('Error!'),
                  detail: message
                });
              });
            localStorage.removeItem('currentUser');
            return of(this.router.createUrlTree(['/login']));
          })
        );
      })
    );
  }

  private resolveRedirect(response: any, currentUser: any): UrlTree {
    if (response.projects) {
      return this.router.createUrlTree(['/project']);
    } else if (response.employees) {
      return this.router.createUrlTree(['/employee']);
    } else if (response.agency_profile) {
      return this.router.createUrlTree(['/agency/profile', currentUser.agency_id]);
    } else if (response.agency_client) {
      return this.router.createUrlTree(['/agency-client/profile', currentUser.agency_id]);
    } else if (response.orders) {
      return this.router.createUrlTree(['/orders']);
    } else if (response.client_work_validation) {
      return this.router.createUrlTree(['/client-work-validation/dashboard']);
    } else {
      this.translate.get('You do not have permissions to continue. Redirecting to login...')
        .subscribe((message) => {
          this.messageService.add({
            severity: 'error',
            summary: this.translate.instant('Error!'),
            detail: message
          });
        });
      localStorage.removeItem('currentUser');
      return this.router.createUrlTree(['/login']);
    }
  }
}
